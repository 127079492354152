import styles from '../../TreeDisplayTile/TileLayout.module.scss';
import { Reset } from '@carbon/icons-react';

export default function ResetButton(props: ResetButtonProps) {
  return (
    <button onClick={props.onClick} className={styles.tileButton}><Reset /></button>
  );
}

interface ResetButtonProps {
  onClick: () => void
}
