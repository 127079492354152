import useTree from '../../../tree/useTree';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import FullScreenImageViewer from '../../../components/FullScreenImageViewer/FullScreenImageViewer';
import { Accordion, AccordionItem, Button } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import { useTreeInfoProperties } from '../../../properties/usePropertyConfigurations';
import { CloseLarge } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../../components/UI/Page/carbon/PageLayout';

export default function QuickTreeDetails() {
  const { t } = useTranslation();
  const { urlContext } = useContext(DependencyInjectionContext);
  const account = useCurrentAccount();
  const navigate = useNavigate();
  const treeId = urlContext.getTreeId() ?? '';
  const { tree } = useTree(account.organization.id, treeId);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const closeImageViewer = () => setSelectedImageUrl('');

  const thumbnailUrl = tree?.getMainImageThumbnailURL();

  const [imageUri, setImageUri] = useState<string | null>(null);

  useEffect(() => {
    if (!tree) return;
    if (!thumbnailUrl) return;

    tree.getMainImageThumbnailFromLayersURL?.()
      .then((i: any) => setImageUri(i))
      .catch(() => setImageUri(thumbnailUrl as string));
  }, [tree?.id, thumbnailUrl]);

  const imageContainerStyle: CSSProperties = tree?.hasImages()
    ? { backgroundImage: `url('${imageUri}')` }
    : {};

  const { dimensionProperties, ecosystemProperties, healthProperties, safetyProperties } = useTreeInfoProperties();

  const accordions: { title: string, properties: DisplayableTreeProperty[] }[] = [
    { title: 'treePropertyGroups.dimensions', properties: dimensionProperties },
    { title: 'treePropertyGroups.ecosystemServices', properties: ecosystemProperties },
    {
      title: 'treePropertyGroups.healthIndication',
      properties: healthProperties
    },
    { title: 'treePropertyGroups.safety', properties: safetyProperties }
  ];

  if (!tree) return <></>;
  const navigateToTreeDetails = () => navigate(`/organizations/${account.organization.id}/inventory/trees/${tree.id}`);

  return (
    <>
      <PageLayout.RightPanelContent>
        <div className="p-4 h-full">
          <div className="flex justify-between items-center mb-8 text-[var(--cds-text-primary)]">
            <div className="font-semibold ">{`${t('treeDetails.tree')} ${tree.externalId}`}</div>
            <div className="p-2 cursor-pointer" onClick={() => urlContext.setTreeId('')}><CloseLarge /></div>
          </div>
          <div
            className="h-44 bg-center bg-cover bg-black-haze-600 cursor-pointer"
            style={imageContainerStyle}
            onClick={() => setSelectedImageUrl(tree?.getMainImageURL() ?? '')}
          />
          <div className="my-4 text-sm">
            {/*TODO: apply italic to taxonomy fields based on specification*/}
            <div className="flex items-center gap-2">
              <div className="text-[var(--cds-text-secondary)] w-20">{t('tree.genus')}</div>
              <div className="text-[var(--cds-text-primary)]">
                {tree.genus}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-[var(--cds-text-secondary)] w-20">{t('tree.species')}</div>
              <div className="text-[var(--cds-text-primary)]">
                {tree.species}
              </div>
            </div>
          </div>
          <Accordion>
            {accordions.map(it => (
              <AccordionItem
                title={t(it.title)}
              >
                {it.properties.map((property, index) => {
                  const unit = Tree.getUnit(property, account.organization);
                  const value =
                property === DisplayableTreeProperty.SafetyFactors
                  ? tree?.[Tree.getDefaultSafetyFactorProperty(account)]
                  : tree?.[property];

                  return (
                    <div key={`tree-${index}-${property}`} className="flex items-center border-0 border-solid border-b last:border-b-0 border-[var(--cds-border-strong)] py-3 text-sm">
                      <div className="text-[var(--cds-text-secondary)] flex-1">{t(`tree.${property}`)}</div>
                      <div className="text-[var(--cds-text-primary)] flex-1 flex gap-0.5">
                        {value ? (
                          <>
                            {typeof value === 'number' ? value!.toFixed(1) : value || '-'}
                            {unit && <>{t('units.' + unit)}</>}
                          </>
                        ) : ('-')}
                      </div>
                    </div>
                  );
                })}
              </AccordionItem>
            ))}

          </Accordion>
        </div>
      </PageLayout.RightPanelContent>
      <PageLayout.RightPanelActions>
        <Button className="w-full min-w-full" onClick={navigateToTreeDetails}>
          {t('treeInfo.details')}
        </Button>
      </PageLayout.RightPanelActions>
      {selectedImageUrl !== '' && <FullScreenImageViewer url={selectedImageUrl} onClose={closeImageViewer} />}
    </>
  );
}
