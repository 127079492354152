import { StreetViewProps } from './useStreetViewProps';
import { TileButton } from '../TileLayout';
import { Ruler, Tree } from 'iconoir-react';
import { ReactComponent as DigitalTwinToggle } from '../../../Explore/images/gh-icon-twin-tree.svg';
import getRuntimeConfig from '../../../../RuntimeConfig';

export default function StreetViewBottomCenterButtons(props: StreetViewProps) {
  return <>
    {getRuntimeConfig().enhancedPointCloud && props.showPointcloud && <TileButton
      onClick={() => props.setHideCanopy(state => !state)}
      icon={<Tree/>}
      active={!props.hideCanopy}
    />}
    <TileButton
      onClick={() => props.setPointcloudVisibility(state => !state)}
      icon={<DigitalTwinToggle/>}
      active={props.showPointcloud}
    />
    <TileButton
      onClick={() => props.setLineMeasurementEnabled(state => !state)}
      icon={<Ruler/>}
      active={props.lineMeasurementEnabled}
    />
  </>;
}
