import DetailedTree from '../../../tree/DetailedTree';
import { useTranslation } from 'react-i18next';
import { InfoCircle } from 'iconoir-react';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';
import { useState } from 'react';
import { Accordion, AccordionItem } from '@carbon/react';

export default function Static({ tree }: { tree: DetailedTree | null }) {
  const { t } = useTranslation();
  const [openAccordionItem, setOpenAccordionItem] = useState<string | null>(null);

  const handleToggle = (id: string) => {
    setOpenAccordionItem(prev => (prev === id ? null : id));
  };

  if (!tree) return null;

  return (
    <div className="twp text-greehill-00 pb-6">
      <Accordion>
        <AccordionItem
          title={t('treeDetails.inventory.siteFactors')}
          open={openAccordionItem === t('treeDetails.inventory.siteFactors')}
          onHeadingClick={() => handleToggle(t('treeDetails.inventory.siteFactors'))}
        >
          <ul className="divide-y divide-outer-space-500 [&>li]:py-2 [&>li]:flex px-0">
            <StaticItem property={'streetAddress'} value={tree.streetAddress} />
            <StaticItem property={'onStreetName'} value={tree.onStreetName} />
            <StaticItem property={'addressFromParcel'} value={tree.addressFromParcel} />
            <StaticItem property={'sideLocation'} value={tree.sideLocation} />
            <StaticItem property={'landUse'} value={tree.landUse?.join(', ')} />
            <StaticItem property={'overheadUtilities'} value={tree.overheadUtilities} />
            <StaticItem property={'growSpaceSize'} value={tree.growSpaceSize} />
            <StaticItem property={'growSpace'} value={tree.growSpace} />
            <StaticItem property={'parkName'} value={tree.parkName} />
          </ul>
        </AccordionItem>
      </Accordion>
      <Accordion>
        <AccordionItem
          title={t('treeDetails.inventory.additionalInformation')}
          open={openAccordionItem === t('treeDetails.inventory.additionalInformation')}
          onHeadingClick={() => handleToggle(t('treeDetails.inventory.additionalInformation'))}
        >
          <ul className="divide-y divide-outer-space-500 [&>li]:py-2 [&>li]:flex">
            <StaticItem property={'customerTagId'} value={tree.customerTagId} />
            <StaticItem property={'customerSiteId'} value={tree.customerSiteId} />
            <StaticItem property={'customerTreeId'} value={tree.customerTreeId} />
            <StaticItem
              property={'cultivarOrVariety'}
              value={tree.cultivarOrVariety}
              userUpdatedProperties={tree.userUpdatedProperties} />
            <StaticItem property={'plantingYear'} value={tree.plantingYear?.toString()} />
            <StaticItem property={'owner'} value={tree.owner} />
          </ul>
        </AccordionItem>
      </Accordion>
      <Accordion>
        <AccordionItem
          title={t('treeDetails.inventory.latestPruningAction')}
          open={openAccordionItem === t('treeDetails.inventory.latestPruningAction')}
          onHeadingClick={() => handleToggle(t('treeDetails.inventory.latestPruningAction'))}
        >
          <ul className="divide-y divide-outer-space-500 [&>li]:py-2 [&>li]:flex">
            <StaticItem property={'latestPruningAction'} />
          </ul>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

function StaticItem({ property, value, userUpdatedProperties }: {
  property: string,
  value?: string,
  userUpdatedProperties?: string[]
}) {
  const { t } = useTranslation();
  return (
    <li className="flex">
      <div className="w-2/5 text-outer-space-200">{t('details.properties.' + property)}</div>
      <div className="w-3/5 font-medium flex items-center justify-between">
        {value ? value : <span className="text-outer-space-500">-</span>}
        {userUpdatedProperties?.includes(property) && (
          <span className="bg-warn-chardonnay-600 flex items-center rounded-full">
            <Tooltip overlay={t('details.manuallyOverwrittenData')} placement="left">
              <InfoCircle/>
            </Tooltip>
          </span>
        )}
      </div>
    </li>
  );
}
