import styles from './Workspace.module.scss';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DisplayableTreeProperty, Tree } from '../../tree/Tree';
import { usePropertyConfigurations } from '../../properties/usePropertyConfigurations';
import Spinner from '../../components/UI/Spinner/Spinner';
import { useCurrentAccount } from '../../account/useAccounts';
import TableDashboard from './components/TableDashboard';
import { TFunction, useTranslation } from 'react-i18next';
import { useFetchTreeList } from './useFetchTreeList';
import { TreeFilter } from '../../tree-filter/TreeFilter';
import LazyTreeList from './LazyTreeList';
import { TaskTemplateWithTrees } from '../TaskManager/create/TaskTemplate';
import {
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@carbon/react';
import PartialTree from '../../tree/PartialTree';
import { SortButton, SortingDirection } from './SortButton';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import { ColorPalette, PresentationFile } from '@carbon/icons-react';
import GraphModal from '../Explore/workspace/components/GraphModal';
import useManagedAreas from '../../managed-area/useManagedAreaList';
import Tooltip from '../../components/UI/Tooltip/Tooltip';

export enum TreeTableVersion {
  TASK_CREATION,
  INSIGHTS
}

export default function TreeTable(props: TreeTableProps) {
  const propertyConfigs = usePropertyConfigurations();
  const account = useCurrentAccount();
  const { t } = useTranslation();
  const { managedAreaList } = useManagedAreas(account.organization.id);

  const [trees, setTrees] = useState<PartialTree[]>([]);
  const { urlContext } = useContext(DependencyInjectionContext);

  const [sort, setSort] = useState<string | null>(DisplayableTreeProperty.ExternalId);
  const [graphModalProperty, setGraphModalProperty] = useState<string | null>(null);

  const { treeList, firstPageIsLoading } = useFetchTreeList(0, sort, props.filters, props.properties, props.taskTemplate?.id);

  const [columnSettings, setColumnSettings] = useState(
    new Map<Property, ColumnSettings>(props.properties.map((property): [Property, ColumnSettings] => [
      property,
      {
        sort: property === DisplayableTreeProperty.ExternalId ? SortingDirection.ASCENDING : null,
        aggregateFunction: getAggregationOptions(t, getColumnConfiguration(property))[0]?.value ?? null }
    ]))
  );
  const treeListTableRef = useRef<HTMLDivElement | null>(null);
  const fullSizedContainerRef = useRef<HTMLDivElement | null>(null);

  const useEffectDependencyForColumnSettingInitialization = props.properties.join('');

  useEffect(() => {
    setColumnSettings(
      columnSettings =>
        new Map<Property, ColumnSettings>([
          ['externalId', { sort: SortingDirection.ASCENDING, aggregateFunction: null }],
          ...props.properties.map((property): [Property, ColumnSettings] => [
            property,
            columnSettings.get(property) ?? {
              sort: null,
              aggregateFunction: getAggregationOptions(t, getColumnConfiguration(property))[0]?.value ?? null
            }
          ])
        ])
    );
  }, [useEffectDependencyForColumnSettingInitialization]);

  useEffect(() => {
    if (!treeList) return;
    treeList.getAll().then(trees => {
      setTrees(trees);
      return trees.map(it => it.id);
    });
    return () => treeList?.cancel();
  }, [treeList]);

  function toggleSort(property: DisplayableTreeProperty | 'externalId', direction?: SortingDirection | null) {
    return () => {
      const original = columnSettings.get(property) ?? { sort: null, aggregateFunction: null };
      const updated = new Map<Property, ColumnSettings>(
        Array.from(columnSettings, it => [it[0], { ...it[1], sort: null }])
      );
      let value: SortingDirection | null = original.sort === null ? SortingDirection.ASCENDING : original.sort === SortingDirection.DESCENDING ? null : SortingDirection.DESCENDING;
      if (direction) {
        value = original.sort === direction ? null : direction;
      }
      updated.set(property, { sort: value, aggregateFunction: original.aggregateFunction });
      let sortProperty: string = property;
      if (property === 'managedAreaId') {
        sortProperty = 'managedAreaName';
      }
      const prefix = value === SortingDirection.DESCENDING ? '-' : '';
      const sortPropertyWithDirection = value === null ? null : prefix + sortProperty;
      setSort(sortPropertyWithDirection);
      setColumnSettings(updated);
    };
  }

  if (propertyConfigs.isLoading) return <Spinner />;
  return <>
    {props.open && (
      <div
        className={`${styles.treeListTableContainer}`}
        onMouseEnter={() => {
          if (props.properties.length * 200 + 100 > window.innerWidth) {
            const arrows = document.getElementsByClassName(styles.floatingArrow);
            for (let i = 0; i < arrows.length; i++) {
              const arrow = arrows.item(i);
              (arrow as HTMLDivElement).style.visibility = 'visible';
            }
          }
        }}
        onMouseLeave={() => {
          const arrows = document.getElementsByClassName(styles.floatingArrow);
          for (let i = 0; i < arrows.length; i++) {
            const arrow = arrows.item(i);
            (arrow as HTMLDivElement).style.visibility = 'hidden';
          }
        }}
      >
        <TableDashboard
          treeList={treeList as LazyTreeList}
          windSpeed={props.windSpeed}
          properties={props.properties}
        />
        <div className="mx-8 h-full">
          <div className="text-sm leading-[18px] tracking-tight mb-1">
            {`${t('total')} ${treeList?.getTotalFilteredByArea() || 0} / ${t('filtered')} ${treeList?.getTotalFilteredByArea() || 0}`}
          </div>
          {props.areaFilterIsSelected ?
            <div ref={treeListTableRef} className="h-full">
              <div ref={fullSizedContainerRef} className="h-full">
                <Table size="lg" useZebraStyles={false} stickyHeader={true}>
                  <TableHead>
                    <TableRow>
                      {props.properties.map(property => {
                        const unit = Tree.getUnit(property, account.organization);
                        const translatedUnit = unit ? ` [${t(`units.${unit}`)}]` : '';
                        const columnTitle = t(`tree.${property}`) + translatedUnit;

                        return <TableHeader id={property} key={property}>
                          <div className="flex justify-between items-center absolute top-0 pt-1">
                            <Tooltip overlay={columnTitle}>
                              <span className="max-w-16 truncate">{columnTitle}</span>
                            </Tooltip>
                            <SortButton
                              sortingDirection={columnSettings.get(property)?.sort}
                              onClick={toggleSort(property)}
                            />
                            <OverflowMenu menuOptionsClass="w-fit">
                              <OverflowMenuItem
                                itemText={<ColorPalette/>}
                                onClick={() => {
                                  if (urlContext.getSelectedTreeProperty() === property) {
                                    urlContext.setSelectedTreeProperty(null);
                                  } else {
                                    urlContext.setSelectedTreeProperty(property as DisplayableTreeProperty);
                                  }
                                }
                                }
                              />
                              <OverflowMenuItem
                                itemText={<PresentationFile />}
                                onClick={() => setGraphModalProperty(property)}
                              />
                            </OverflowMenu>
                          </div>
                        </TableHeader>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trees.map(tree =>
                      <TableRow key={tree.id}>
                        {props.properties.map(property => {
                          if (property === DisplayableTreeProperty.Genus || property === DisplayableTreeProperty.Species) {
                            return <TableCell key={property} className="italic">{tree[property]}</TableCell>;
                          }
                          if (Number(tree[property]) && property !== DisplayableTreeProperty.ExternalId) {
                            return <TableCell key={property} className="text-right">{Number(tree[property]).toFixed(2)}</TableCell>;
                          }
                          return <TableCell key={property}>
                            {tree[property]}
                          </TableCell>;
                        })}
                      </TableRow>)}
                  </TableBody>
                </Table>
              </div>
            </div>
            : <EmptyState/>}
          {firstPageIsLoading && <Spinner/>}
        </div>
      </div>
    )}
    {graphModalProperty && <GraphModal
      onClose={() => setGraphModalProperty(null)}
      property={graphModalProperty}
      managedAreas={managedAreaList}
    />}
  </>;
}

function EmptyState() {
  const { t } = useTranslation();

  return <div className={styles.emptyState}>
    <h2>{t('workspace.emptyState')}</h2>
    <p>{/*Lorem ipsum*/}</p>
  </div>;
}
export function getAggregationOptions(t: TFunction, configuration: ColumnConfiguration) {
  return [
    { label: t('treeList.min'), value: 'min' as const },
    { label: t('treeList.max'), value: 'max' as const },
    { label: t('treeList.sum'), value: 'sum' as const },
    { label: t('treeList.avg'), value: 'avg' as const },
    { label: t('treeList.med'), value: 'med' as const }
  ].filter(it => configuration[it.value]);
}

export type Property = DisplayableTreeProperty | 'externalId';

export interface ColumnConfiguration {
  min: boolean,
  max: boolean,
  avg: boolean,
  med: boolean,
  sort: boolean,
  sum: boolean,
  graph: boolean
}

export const defaultColumnConfiguration: ColumnConfiguration = {
  min: false,
  max: false,
  avg: false,
  med: false,
  sort: false,
  sum: false,
  graph: false
};

const numericColumnConfiguration: ColumnConfiguration = { min: true, max: true, avg: true, med: true, sort: true, sum: false, graph: true };
const enumColumnConfiguration: ColumnConfiguration = { min: false, max: false, avg: false, med: false, sort: true, sum: false, graph: true };

export const columnConfiguration = new Map<Property, ColumnConfiguration>([
  ['externalId', defaultColumnConfiguration],
  [DisplayableTreeProperty.ScientificName, enumColumnConfiguration],
  [DisplayableTreeProperty.Species, enumColumnConfiguration],
  [DisplayableTreeProperty.Genus, enumColumnConfiguration],
  [DisplayableTreeProperty.ManagedAreaId, enumColumnConfiguration],
  [DisplayableTreeProperty.Status, enumColumnConfiguration],
  [DisplayableTreeProperty.VitalityVigor, enumColumnConfiguration],
  [DisplayableTreeProperty.Height, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkHeight, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyHeight, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyWidth, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkCircumference, numericColumnConfiguration],
  [DisplayableTreeProperty.TrunkDiameter, numericColumnConfiguration],
  [DisplayableTreeProperty.CanopyCircumference, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactorAt80Kmh, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed, numericColumnConfiguration],
  [DisplayableTreeProperty.SafetyFactors, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafArea, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafBiomass, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafAreaIndex, numericColumnConfiguration],
  [DisplayableTreeProperty.CarbonStorage, numericColumnConfiguration],
  [DisplayableTreeProperty.GrossCarbonSequestration, numericColumnConfiguration],
  [DisplayableTreeProperty.NO2, numericColumnConfiguration],
  [DisplayableTreeProperty.SO2, numericColumnConfiguration],
  [DisplayableTreeProperty.PM25, numericColumnConfiguration],
  [DisplayableTreeProperty.CO, numericColumnConfiguration],
  [DisplayableTreeProperty.O3, numericColumnConfiguration],
  [DisplayableTreeProperty.NDVI, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeHealth, numericColumnConfiguration],
  [DisplayableTreeProperty.PotentialEvapotranspiration, numericColumnConfiguration],
  [DisplayableTreeProperty.Transpiration, numericColumnConfiguration],
  [DisplayableTreeProperty.OxygenProduction, numericColumnConfiguration],
  [DisplayableTreeProperty.ThermalComfort, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueRado, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueCavat, numericColumnConfiguration],
  [DisplayableTreeProperty.TreeValueKoch, numericColumnConfiguration],
  [DisplayableTreeProperty.LeaningAngle, numericColumnConfiguration],
  [DisplayableTreeProperty.WaterIntercepted, numericColumnConfiguration],
  [DisplayableTreeProperty.Evaporation, numericColumnConfiguration],
  [DisplayableTreeProperty.AvoidedRunoff, numericColumnConfiguration],
  [DisplayableTreeProperty.Dieback, numericColumnConfiguration],
  [DisplayableTreeProperty.Slenderness, numericColumnConfiguration],
  [DisplayableTreeProperty.LiveCrownRatio, numericColumnConfiguration],
  [DisplayableTreeProperty.CrownLightExposure, numericColumnConfiguration],
  [DisplayableTreeProperty.LeafAreaPerCrownVolume, numericColumnConfiguration],
  [DisplayableTreeProperty.ViStatus, enumColumnConfiguration],
  [DisplayableTreeProperty.HasMitigation, enumColumnConfiguration],
  [DisplayableTreeProperty.HasAssessmentRequest, enumColumnConfiguration],
  [DisplayableTreeProperty.HasViObservation, enumColumnConfiguration],
  [DisplayableTreeProperty.Mitigations, enumColumnConfiguration],
  [DisplayableTreeProperty.AssessmentRequests, enumColumnConfiguration],
  [DisplayableTreeProperty.ViObservations, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierHeightPerCrownVolume, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierHeightPerLeafArea, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierTrunkDiameterPerHeight, enumColumnConfiguration],
  [DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea, enumColumnConfiguration],
  [DisplayableTreeProperty.OverallOutlierIndex, numericColumnConfiguration]
]);

export function getColumnConfiguration(property: Property) {
  return columnConfiguration.get(property) ?? defaultColumnConfiguration;
}

export interface ColumnSettings {
  sort: null | SortingDirection,
  aggregateFunction: null | 'min' | 'max' | 'avg' | 'med' | 'sum'
}

interface TreeTableProps {
  filters: TreeFilter[],
  properties: Property[],
  areaFilterIsSelected: boolean,
  windSpeed: number,
  open: boolean,
  taskTemplate?: TaskTemplateWithTrees
}
