import { Button } from '@carbon/react';
import { SortAscending, SortDescending, ArrowsVertical } from '@carbon/icons-react';

export enum SortingDirection {
  DESCENDING = 'desc',
  ASCENDING = 'asc'
}
export const SortButton = (props: SortButtonProps) => {
  return (
    <Button
      onClick={props.onClick}
      kind="ghost"
      size="md"
    >
      {!props.sortingDirection && <ArrowsVertical />}
      {props.sortingDirection === SortingDirection.DESCENDING && <SortDescending />}
      {props.sortingDirection === SortingDirection.ASCENDING && <SortAscending />}
    </Button>
  );
};

export interface SortButtonProps {
  sortingDirection: SortingDirection | null | undefined,
  onClick: () => void
}
