import {
  BufferAttribute,
  BufferGeometry,
  Group,
  Points,
  ShaderMaterial,
  Vector3
} from 'three';
import LAZRSWebAssemblyMain from './laz_rs_webassembly';
import { DefaultLasLoaderStyle } from './styles/default.las-loader-style';
import { LasLoaderStyle } from './las-loader-style';

const vertexShader = `
  attribute vec3 color;
  attribute float opacity;
  attribute float intensity;
  attribute float visible;

  varying vec3 vColor;
  varying vec3 vPosition;
  varying float vOpacity;
  varying float vIntensity;

  void main() {
      vColor = color;
      vOpacity = opacity;
      vIntensity = intensity;
      vPosition = position;

      if (visible == 0.0) {
        vOpacity = 0.0;
      }

      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      gl_PointSize = 2.;
  }
`;

const fragmentShader = `
  varying vec3 vColor;
  varying float vOpacity;
  varying float vIntensity;
  varying vec3 vPosition;

  void main() {    
      if(vOpacity == 0.0) discard;
      vec3 color = vColor * vIntensity;
      color = pow(color, vec3(1./2.2));
      gl_FragColor = vec4(color, vOpacity);
  }
`;

export class LasLoader extends Group {
  public loaded = false;

  private loader = new LAZRSWebAssemblyMain({
    libPath: '/laz-rs-webassembly/laz_rs_webassembly-lib.js',
    workerPath: '/laz-rs-webassembly/laz_rs_webassembly-worker.js',
    wasmPath: '/laz-rs-webassembly/laz_rs_webassembly-lib_bg.wasm'
  });

  private lasOffset = new Vector3();
  private lasScale = new Vector3();
  private lasMin = new Vector3();
  private lasMax = new Vector3();
  private lasData: any = null;
  private hideCanopy = false;
  private style: LasLoaderStyle = new DefaultLasLoaderStyle();

  private pointCloud = new Points(
    new BufferGeometry(),
    new ShaderMaterial({
      vertexShader,
      fragmentShader,
      transparent: true
    })
  );

  constructor() {
    super();

    this.pointCloud.frustumCulled = false;
    this.add(this.pointCloud);
  }

  public setCanopyVisibility(value: boolean | undefined) {
    this.hideCanopy = !!value;
    this.updateVisibility();
  }

  public async load(url: string, signal?: AbortSignal) {
    try {
      const r = new Request(url, {
        method: 'GET',
        credentials: 'include',
        signal
      });
      const result = await fetch(r);
      const ab = await result.arrayBuffer();
      const bytes = new Uint8Array(ab);

      return this.loadBytes(bytes);
    } catch (e) {
      //console.log(e);
    }
  }

  public async loadBytes(bytes: Uint8Array) {
    this.lasData = await this.loader.read(bytes);

    this.lasOffset = new Vector3(
      this.lasData.header.offset_x,
      this.lasData.header.offset_y,
      this.lasData.header.offset_z
    );

    this.lasScale = new Vector3(
      this.lasData.header.scale_x,
      this.lasData.header.scale_y,
      this.lasData.header.scale_z
    );

    this.lasMin = new Vector3(
      this.lasData.header.min_x,
      this.lasData.header.min_y,
      this.lasData.header.min_z
    );

    this.lasMax = new Vector3(
      this.lasData.header.max_x,
      this.lasData.header.max_y,
      this.lasData.header.max_z
    );

    this.update();
    this.loaded = true;

    return {
      data: this.lasData,
      offset: this.lasOffset,
      scale: this.lasScale,
      min: this.lasMin,
      max: this.lasMax
    };
  }

  public updateVisibility() {
    const visibilities: number[] = [];
    for (let i = 0; i < this.lasData?.header.num_points; i++) {
      const classification = this.lasData?.classifications[i];

      const visible = (!this.hideCanopy || classification !== 21) && this.style.getPointVisible(classification, 1);

      visibilities.push(visible ? 1 : 0);
    }
    this.pointCloud.geometry.setAttribute('visible', new BufferAttribute(new Float32Array(visibilities), 1));
  }

  public update() {
    const colors: number[] = [];
    const opacity: number[] = [];
    const intensities: number[] = [];
    const visibilities: number[] = [];
    for (let i = 0; i < this.lasData?.header.num_points; i++) {
      const classification = this.lasData?.classifications[i];
      const intensity = 1 - Math.min(this.lasData?.intensities[i] / 65535, 1);

      const [r, g, b, o] = this.style.getPointColor(classification, intensity);
      const visible = (!this.hideCanopy || classification !== 21) && this.style.getPointVisible(classification, intensity);

      colors.push(r, g, b);
      opacity.push(o);
      visibilities.push(visible ? 1 : 0);
      intensities.push(intensity);
    }
    this.pointCloud.geometry.setAttribute('color', new BufferAttribute(new Float32Array(colors), 3));
    this.pointCloud.geometry.setAttribute('opacity', new BufferAttribute(new Float32Array(opacity), 1));
    this.pointCloud.geometry.setAttribute('intensity', new BufferAttribute(new Float32Array(intensities), 1));
    this.pointCloud.geometry.setAttribute('visible', new BufferAttribute(new Float32Array(visibilities), 1));
    this.pointCloud.geometry.setAttribute('position', new BufferAttribute(new Float32Array(this.lasData?.coordinates), 3));
  }

  public dispose() {
    this.pointCloud.removeFromParent();
    this.pointCloud.material.dispose();
    this.pointCloud.geometry.dispose();
  }
}
