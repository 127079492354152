import { TileButton, TileButtonGroup } from '../TileLayout';
import { StreetViewProps } from './useStreetViewProps';
import ResetButton from '../components/ResetButton';
import ZoomIn from '@carbon/icons-react/lib/ZoomIn';
import ZoomOut from '@carbon/icons-react/lib/ZoomOut';

export default function StreetViewRightBottomButtons(props: StreetViewProps) {
  return <>
    <ResetButton onClick={props.resetView} />
    <TileButtonGroup>
      <TileButton onClick={() => props.viewRef.current?.zoomIn()} icon={<ZoomIn />} />
      <TileButton onClick={() => props.viewRef.current?.zoomOut()} icon={<ZoomOut />} />
    </TileButtonGroup>
  </>;
}
