import { useTranslation } from 'react-i18next';

export function ObservationItem(props: ObservationItemProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-sm leading-[18px] tracking-tight">
        {`${t('treeDetails.dataCollection.observation')} ${props.observationCount}`}
      </div>
      <div className="pt-3 pb-6 justify-start items-start gap-4 inline-flex">
        <div className="text-sm leading-[18px] tracking-tight">{t('treeDetails.dataCollection.observation')}<br />{t('treeDetails.dataCollection.recommendation')}</div>
        <div className="text-sm leading-[18px] tracking-tight">{t(`virtualInspection.observationOptions.${props.observationName}`)}<br />{props.recommendation}</div>
      </div>
    </>
  );
}

interface ObservationItemProps {
  observationCount: number,
  observationName: string,
  recommendation: string
}
