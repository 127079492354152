import * as THREE from 'three';
import { Tree } from '../tree/Tree';
import { LasLoader } from '../components/ImprovedLasLoader/las-loader';
import { LoadablePointCloudInterface } from './LoadablePointCloud';

let cache: Record<string, { pointCloudCenter: number[], loader: LasLoader }> = {};

export class LoadableShaderPointCloud extends THREE.Group implements LoadablePointCloudInterface {
  private treeId: string | null = null;
  private signal: AbortController = new AbortController();

  constructor(
    private readonly url: string
  ) {
    super();
  }

  loadIntoWithTreeId(treeId: string, position: number[], pointsMaterial?: THREE.PointsMaterial, hideCanopy?: boolean): Promise<LoadableShaderPointCloud> {
    this.treeId = treeId;
    return this.loadInto(position, false, pointsMaterial, hideCanopy);
  }

  belongsTo(tree: Tree) {
    return this.treeId === tree.id;
  }

  loadInto(position: number[], isEnvironment: boolean, pointsMaterial?: THREE.PointsMaterial, hideCanopy?: boolean): Promise<LoadableShaderPointCloud> {
    return new Promise<LoadableShaderPointCloud>(resolve => {
      const cacheKey = this.getCacheKey(!!pointsMaterial);
      const cached = cache[cacheKey];
      if (cached) {
        this.setupFromData(position, cached.pointCloudCenter, cached.loader, !!hideCanopy);
        return resolve(this);
      }
      const loader = new LasLoader();
      loader.load(this.url, this.signal.signal).then(d => {
        if (d) {
          const pointCloudCenter = [d?.offset.x, d?.offset.y, d?.offset.z];
          this.setupFromData(position, pointCloudCenter, loader as any, !!hideCanopy);

          const size = Object.values(cache).length;
          if (size > 3) cache = {};
          cache[cacheKey] = { pointCloudCenter, loader };
          resolve(this);
        }
      });
    });
  }

  private getCacheKey(useHistory: boolean) {
    return `${this.url}-${useHistory ? 'history' : 'original'}`;
  }

  private setupFromData(into: number[], center: number[], loader: LasLoader, hideCanopy: boolean) {
    this.position.subVectors(new THREE.Vector3().fromArray(center), new THREE.Vector3().fromArray(into));
    loader.setCanopyVisibility(hideCanopy);
    this.add(loader.clone());
  }
}
