import SpeciesInItalic from '../Explore/workspace/components/SpeciesInItalic';
import { useTranslation } from 'react-i18next';
import DetailedTree from '../../tree/DetailedTree';
import React from 'react';
import { Task } from '../../task-manager/Task';
import { Flippers } from '../../switches/Flippers';
import { useCurrentAccount } from '../../account/useAccounts';
import { GH_DEFAULT } from '../../tree/Tree';
import NoData from './NoData';

export default function Header({ tree }: {
  tree: DetailedTree | null,
  task: Task | null,
  isTreeEdited: boolean
}) {
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();

  const renderedGenus = (organization.isEnabled(Flippers.davey) && tree?.genus === GH_DEFAULT) ? 'Unknown' : (tree?.genus || <NoData />);
  const renderedSpecies = (organization.isEnabled(Flippers.davey) && tree?.species === GH_DEFAULT) ? <i>Unknown</i> : (tree?.species ? <SpeciesInItalic species={tree.species} /> : <NoData />);
  return (
    <div className="flex justify-between w-full twp">
      <div className="flex gap-2 items-center">
        <div className="grid grid-cols-4 gap-5">
          <div>
            <Label label={t('treeDetails.inventory.genus')} />
            <p className="text-sm font-bold"><i>{renderedGenus}</i></p>
          </div>
          <div>
            <Label label={t('treeDetails.inventory.species')} />
            <p className="text-sm font-bold">{renderedSpecies}</p>
          </div>
          <div>
            <Label label={t('details.properties.commonName')} />
            <p className="text-sm font-bold">{tree?.commonName || <NoData />}</p>
          </div>
          <div>
            <Label label={t('details.properties.address')} />
            <p className="text-sm font-bold">{tree?.streetAddress || <NoData />}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const Label = ({ label }: { label: string }) => {
  return (
    <span className="text-sm">{label}</span>
  );
};
