import { TreeFilter } from '../tree-filter/TreeFilter';
import { MercatorCoordinate } from '../routes/Explore/MercatorCoordinate';
import { ManagedAreaDto } from '../managed-area/ManagedAreaDto';
import { ManagedArea } from '../managed-area/ManagedArea';
import { TreeImage } from './TreeImage';
import { ThresholdMatchingState } from '../routes/Explore/tree-marker/ThresholdMatchingState';
import { Organization } from '../organization/Organization';
import { Account } from '../account/Account';
import { Cohort } from '../routes/LegacyDetails/LegacyDetails';
import { TreeEnvironment, ViStatus } from './DetailedTree';
import { TFunction } from 'react-i18next';
import { CrossSectionalShape } from '../property-enums/CrossSectionalShape';
import getRuntimeConfig from '../RuntimeConfig';
import TaskTemplate from '../routes/TaskManager/create/TaskTemplate';

export type Longitude = number;
export type Latitude = number;

export enum Fork {
  VSHAPE = 'vShape',
  USHAPE = 'uShape'
}

export const GH_DEFAULT = 'gh_default';

export enum DisplayableTreeProperty {
  Height = 'height',
  TrunkHeight = 'trunkHeight',
  CanopyHeight = 'canopyHeight',
  CanopyWidth = 'canopyWidth',
  TrunkCircumference = 'trunkCircumference',
  CanopyCircumference = 'canopyCircumference',
  TrunkDiameter = 'trunkDiameter',
  Evaporation = 'evaporation',
  WaterIntercepted = 'waterIntercepted',
  AvoidedRunoff = 'avoidedRunoff',
  AvoidedRunoffEcoValue = 'avoidedRunoffEcoValue',

  LeafArea = 'leafArea',
  LeafBiomass = 'leafBiomass',
  LeafAreaIndex = 'leafAreaIndex',
  NDVI = 'ndvi',
  TreeHealth = 'treeHealth',
  CarbonStorage = 'carbonStorage',
  CarbonStorageEcoValue = 'carbonStorageEcoValue',
  GrossCarbonSequestration = 'grossCarbonSequestration',
  GrossCarbonSequestrationEcoValue = 'grossCarbonSequestrationEcoValue',
  NO2 = 'no2',
  NO2EcoValue = 'no2EcoValue',
  SO2 = 'so2',
  SO2EcoValue = 'so2EcoValue',
  PM25 = 'pm25',
  PM25EcoValue = 'pm25EcoValue',
  CO = 'co',
  COEcoValue = 'coEcoValue',
  O3 = 'o3',
  O3EcoValue = 'o3EcoValue',
  PotentialEvapotranspiration = 'potentialEvapotranspiration',
  Transpiration = 'transpiration',
  OxygenProduction = 'oxygenProduction',

  SafetyFactorAt80Kmh = 'safetyFactorAt80Kmh',
  SafetyFactorAtDefaultWindSpeed = 'safetyFactorAtDefaultWindSpeed',
  SafetyFactors = 'safetyFactors',

  ThermalComfort = 'thermalComfort',
  LeaningAngle = 'leaningAngle',

  TreeValueCavat = 'treeValueCavat',
  TreeValueKoch = 'treeValueKoch',
  TreeValueRado = 'treeValueRado',

  Dieback = 'dieback',

  Status = 'status',
  VitalityVigor = 'vitalityVigor',
  CrownLightExposure = 'crownLightExposure',
  LeafAreaPerCrownVolume = 'leafAreaPerCrownVolume',
  LiveCrownRatio = 'liveCrownRatio',
  Slenderness = 'slenderness',

  ViStatus = 'viStatus',

  HasViObservation = 'hasViObservation',
  HasMitigation = 'hasMitigation',
  HasAssessmentRequest = 'hasAssessmentRequest',

  ViObservations = 'viObservations',
  Mitigations = 'mitigations',
  AssessmentRequests = 'assessmentRequests',

  viObservations = 'viObservations',

  PlantingYear = 'plantingYear',
  NumberOfStems = 'numberOfStems',
  Age = 'age',
  CustomerTreeId = 'customerTreeId',
  CustomerTagId = 'customerTagId',
  CustomerSiteId = 'customerSiteId',
  LocalizedLocation = 'localizedLocation',
  Owner = 'owner',
  OnStreetName = 'onStreetName',
  SideLocation = 'sideLocation',
  GrowSpaceSize = 'growSpaceSize',
  OverheadUtilities = 'overheadUtilities',
  ParkName = 'parkName',
  GrowSpace = 'growSpace',
  GrowSpaceType = 'growSpaceType',
  LandUse = 'landUse',

  Genus = 'genus',
  Species = 'species',
  CommonName = 'commonName',
  StreetAddress = 'streetAddress',

  LimbDiameter = 'limbDiameter',
  CoDominantStems = 'coDominantStems',
  IncludedBark = 'includedBark',
  TmsCategory = 'tmsCategory',
  AgeClass = 'ageClass',
  CultivarOrVariety = 'cultivarOrVariety',
  AgeAtPlanting = 'ageAtPlanting',
  CriticalRootZone = 'criticalRootZone',
  StructuralCriticalRootZone = 'structuralCriticalRootZone',
  AddressFromParcel = 'addressFromParcel',
  CrownVolume = 'crownVolume',

  FoliageNoneSeasonal = 'foliageNoneSeasonal',
  FoliageNoneDead = 'foliageNoneDead',
  NormalFoliage = 'normalFoliage',
  ChloroticFoliage = 'chloroticFoliage',
  NecroticFoliage = 'necroticFoliage',

  ScientificName = 'scientificName',
  ManagedAreaId = 'managedAreaId',
  ExternalId = 'externalId',
  TrunkWidth = 'trunkWidth',
  Condition = 'condition',
  PotentialTargets = 'potentialTargets',
  NumberOfLimbs = 'numberOfLimbs',

  AbsoluteWeakestPoint = 'absoluteWeakestPoint',
  FurtherInspectionNeeded = 'furtherInspectionNeeded',
  CrownTransparency = 'crownTransparency',

  OutlierHeightPerCrownVolume = 'outlierHeightPerCrownVolume',
  OutlierHeightPerLeafArea = 'outlierHeightPerLeafArea',
  OutlierLeafAreaPerCrownVolume = 'outlierLeafAreaPerCrownVolume',
  OutlierTrunkDiameterPerCrownVolume = 'outlierTrunkDiameterPerCrownVolume',
  OutlierTrunkDiameterPerHeight = 'outlierTrunkDiameterPerHeight',
  OutlierTrunkDiameterPerLeafArea = 'outlierTrunkDiameterPerLeafArea',
  OverallOutlierIndex = 'overallOutlierIndex'
}

export class Tree {
  static readonly SAFETY_FACTOR_THRESHOLD = 1.5;
  static readonly DEFAULT_WIND_SPEED = 80;
  private static readonly UNIT_MAP = new Map([
    [DisplayableTreeProperty.Height, 'm'],
    [DisplayableTreeProperty.TrunkHeight, 'm'],
    [DisplayableTreeProperty.CanopyHeight, 'm'],
    [DisplayableTreeProperty.CanopyWidth, 'm'],
    [DisplayableTreeProperty.TrunkCircumference, 'm'],
    [DisplayableTreeProperty.CanopyCircumference, 'm'],
    [DisplayableTreeProperty.LeafArea, 'm²'],
    [DisplayableTreeProperty.LeafBiomass, 'kg'],
    [DisplayableTreeProperty.LeafAreaIndex, ''],
    [DisplayableTreeProperty.CarbonStorage, 'kg'],
    [DisplayableTreeProperty.CarbonStorageEcoValue, '€'],
    [DisplayableTreeProperty.GrossCarbonSequestration, 'kg/yr'],
    [DisplayableTreeProperty.GrossCarbonSequestrationEcoValue, '€'],
    [DisplayableTreeProperty.NO2, 'g/yr'],
    [DisplayableTreeProperty.NO2EcoValue, '€'],
    [DisplayableTreeProperty.SO2, 'g/yr'],
    [DisplayableTreeProperty.SO2EcoValue, '€'],
    [DisplayableTreeProperty.PM25, 'g/yr'],
    [DisplayableTreeProperty.PM25EcoValue, '€'],
    [DisplayableTreeProperty.CO, 'g/yr'],
    [DisplayableTreeProperty.COEcoValue, '€'],
    [DisplayableTreeProperty.O3, 'g/yr'],
    [DisplayableTreeProperty.O3EcoValue, '€'],
    [DisplayableTreeProperty.NDVI, ''],
    [DisplayableTreeProperty.TreeHealth, ''],
    [DisplayableTreeProperty.PotentialEvapotranspiration, 'm³/yr'],
    [DisplayableTreeProperty.Transpiration, 'm³/yr'],
    [DisplayableTreeProperty.OxygenProduction, 'kg/yr'],
    [DisplayableTreeProperty.TrunkDiameter, 'm'],
    [DisplayableTreeProperty.AvoidedRunoff, 'm³/yr'],
    [DisplayableTreeProperty.AvoidedRunoffEcoValue, '€'],
    [DisplayableTreeProperty.Evaporation, 'm³/yr'],
    [DisplayableTreeProperty.WaterIntercepted, 'm³/yr'],
    [DisplayableTreeProperty.ThermalComfort, '°C'],
    [DisplayableTreeProperty.TreeValueCavat, '£'],
    [DisplayableTreeProperty.TreeValueKoch, '€'],
    [DisplayableTreeProperty.TreeValueRado, 'Ft'],
    [DisplayableTreeProperty.LeaningAngle, '°'],
    [DisplayableTreeProperty.Dieback, '%'],
    [DisplayableTreeProperty.Slenderness, '%'],
    [DisplayableTreeProperty.LiveCrownRatio, '%'],
    [DisplayableTreeProperty.LimbDiameter, 'cm'],
    [DisplayableTreeProperty.NormalFoliage, '%'],
    [DisplayableTreeProperty.ChloroticFoliage, '%'],
    [DisplayableTreeProperty.NecroticFoliage, '%'],
    [DisplayableTreeProperty.StructuralCriticalRootZone, 'm'],
    [DisplayableTreeProperty.CriticalRootZone, 'm'],
    [DisplayableTreeProperty.TrunkWidth, 'm'],
    [DisplayableTreeProperty.CrownVolume, 'm³'],
    [DisplayableTreeProperty.AbsoluteWeakestPoint, 'm']
  ]);

  private static readonly IMPERIAL_UNIT_MAP = new Map([
    [DisplayableTreeProperty.Height, 'ft'],
    [DisplayableTreeProperty.TrunkHeight, 'ft'],
    [DisplayableTreeProperty.CanopyHeight, 'ft'],
    [DisplayableTreeProperty.CanopyWidth, 'ft'],
    [DisplayableTreeProperty.TrunkCircumference, 'in'],
    [DisplayableTreeProperty.CanopyCircumference, 'ft'],
    [DisplayableTreeProperty.LeafArea, 'sq ft'],
    [DisplayableTreeProperty.LeafBiomass, 'lb'],
    [DisplayableTreeProperty.LeafAreaIndex, ''],
    [DisplayableTreeProperty.CarbonStorage, 'lb'],
    [DisplayableTreeProperty.CarbonStorageEcoValue, '€'],
    [DisplayableTreeProperty.GrossCarbonSequestration, 'lb/yr'],
    [DisplayableTreeProperty.GrossCarbonSequestrationEcoValue, '€'],
    [DisplayableTreeProperty.NO2, 'lb/yr'],
    [DisplayableTreeProperty.NO2EcoValue, '€'],
    [DisplayableTreeProperty.SO2, 'lb/yr'],
    [DisplayableTreeProperty.SO2EcoValue, '€'],
    [DisplayableTreeProperty.PM25, 'lb/yr'],
    [DisplayableTreeProperty.PM25EcoValue, '€'],
    [DisplayableTreeProperty.CO, 'lb/yr'],
    [DisplayableTreeProperty.COEcoValue, '€'],
    [DisplayableTreeProperty.O3, 'lb/yr'],
    [DisplayableTreeProperty.O3EcoValue, '€'],
    [DisplayableTreeProperty.NDVI, ''],
    [DisplayableTreeProperty.TreeHealth, ''],
    [DisplayableTreeProperty.PotentialEvapotranspiration, 'ft³/yr'],
    [DisplayableTreeProperty.Transpiration, 'ft³/yr'],
    [DisplayableTreeProperty.OxygenProduction, 'lb/yr'],
    [DisplayableTreeProperty.TrunkDiameter, 'in'],
    [DisplayableTreeProperty.AvoidedRunoff, 'ft³/yr'],
    [DisplayableTreeProperty.AvoidedRunoffEcoValue, '€'],
    [DisplayableTreeProperty.Evaporation, 'ft³/yr'],
    [DisplayableTreeProperty.WaterIntercepted, 'ft³/yr'],
    [DisplayableTreeProperty.ThermalComfort, '°F'],
    [DisplayableTreeProperty.TreeValueCavat, '£'],
    [DisplayableTreeProperty.TreeValueKoch, '€'],
    [DisplayableTreeProperty.TreeValueRado, 'Ft'],
    [DisplayableTreeProperty.LeaningAngle, '°'],
    [DisplayableTreeProperty.Dieback, '%'],
    [DisplayableTreeProperty.Slenderness, '%'],
    [DisplayableTreeProperty.LiveCrownRatio, '%'],
    [DisplayableTreeProperty.LimbDiameter, 'in'],
    [DisplayableTreeProperty.NormalFoliage, '%'],
    [DisplayableTreeProperty.ChloroticFoliage, '%'],
    [DisplayableTreeProperty.NecroticFoliage, '%'],
    [DisplayableTreeProperty.StructuralCriticalRootZone, 'ft'],
    [DisplayableTreeProperty.CriticalRootZone, 'ft'],
    [DisplayableTreeProperty.TrunkWidth, 'in'],
    [DisplayableTreeProperty.CrownVolume, 'ft³'],
    [DisplayableTreeProperty.AbsoluteWeakestPoint, 'ft']
  ]);

  static readonly METRICAL_PROPERTIES = [
    DisplayableTreeProperty.Height,
    DisplayableTreeProperty.TrunkHeight,
    DisplayableTreeProperty.CanopyHeight,
    DisplayableTreeProperty.CanopyWidth,
    DisplayableTreeProperty.TrunkCircumference,
    DisplayableTreeProperty.TrunkDiameter,
    DisplayableTreeProperty.CanopyCircumference,
    DisplayableTreeProperty.CrownLightExposure
  ];

  static readonly ECOSYSTEM_PROPERTIES = [
    DisplayableTreeProperty.CarbonStorage,
    DisplayableTreeProperty.GrossCarbonSequestration,
    DisplayableTreeProperty.NO2,
    DisplayableTreeProperty.SO2,
    DisplayableTreeProperty.PM25,
    DisplayableTreeProperty.CO,
    DisplayableTreeProperty.O3,
    DisplayableTreeProperty.PotentialEvapotranspiration,
    DisplayableTreeProperty.Transpiration,
    DisplayableTreeProperty.OxygenProduction,
    DisplayableTreeProperty.AvoidedRunoff,
    DisplayableTreeProperty.Evaporation,
    DisplayableTreeProperty.WaterIntercepted,
    DisplayableTreeProperty.ThermalComfort
  ];

  static readonly HEALTH_INDICATIONS = [
    DisplayableTreeProperty.LeafArea,
    DisplayableTreeProperty.LeafAreaIndex,
    DisplayableTreeProperty.NDVI,
    DisplayableTreeProperty.TreeHealth,
    DisplayableTreeProperty.Dieback,
    DisplayableTreeProperty.Status,
    DisplayableTreeProperty.VitalityVigor,
    DisplayableTreeProperty.LeafAreaPerCrownVolume,
    DisplayableTreeProperty.LiveCrownRatio
  ];

  static readonly SAFETY_PROPERTIES = [
    DisplayableTreeProperty.SafetyFactors,
    DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed,
    DisplayableTreeProperty.LeaningAngle,
    DisplayableTreeProperty.Slenderness
  ];

  static readonly ECONOMICAL_VALUE = [
    DisplayableTreeProperty.TreeValueCavat,
    DisplayableTreeProperty.TreeValueKoch,
    DisplayableTreeProperty.TreeValueRado
  ];

  static readonly ECONOMICAL_PROPERTY_VALUE = [
    DisplayableTreeProperty.CarbonStorageEcoValue,
    DisplayableTreeProperty.GrossCarbonSequestrationEcoValue,
    DisplayableTreeProperty.NO2EcoValue,
    DisplayableTreeProperty.SO2EcoValue,
    DisplayableTreeProperty.PM25EcoValue,
    DisplayableTreeProperty.COEcoValue,
    DisplayableTreeProperty.O3EcoValue,
    DisplayableTreeProperty.AvoidedRunoffEcoValue
  ];

  static readonly INSPECTIONS = [
    DisplayableTreeProperty.ViStatus
  ];

  static readonly ENUM_PROPERTIES = [
    DisplayableTreeProperty.VitalityVigor,
    DisplayableTreeProperty.ViStatus,
    DisplayableTreeProperty.Status,
    DisplayableTreeProperty.HasViObservation,
    DisplayableTreeProperty.HasMitigation,
    DisplayableTreeProperty.HasAssessmentRequest,
    DisplayableTreeProperty.ViObservations,
    DisplayableTreeProperty.Mitigations,
    DisplayableTreeProperty.AssessmentRequests,
    DisplayableTreeProperty.Species,
    DisplayableTreeProperty.ManagedAreaId,
    DisplayableTreeProperty.ScientificName,
    DisplayableTreeProperty.Genus,
    DisplayableTreeProperty.OutlierHeightPerCrownVolume,
    DisplayableTreeProperty.OutlierHeightPerLeafArea,
    DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerHeight,
    DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea
  ];

  static readonly IDENTIFICATIONS = [
    DisplayableTreeProperty.Genus,
    DisplayableTreeProperty.Species,
    DisplayableTreeProperty.ScientificName
  ];

  static readonly TRANSLATABLE_ENUM_PROPERTIES = this.ENUM_PROPERTIES.filter(it =>
    ![
      DisplayableTreeProperty.Species,
      DisplayableTreeProperty.ManagedAreaId,
      DisplayableTreeProperty.ScientificName,
      DisplayableTreeProperty.Genus
    ].includes(it)
  );

  static readonly ITALIC_PROPERTIES: string[] = [
    DisplayableTreeProperty.ScientificName,
    DisplayableTreeProperty.Species,
    DisplayableTreeProperty.Genus
  ];

  static readonly OUTLIER_PROPERTIES = [
    DisplayableTreeProperty.OutlierHeightPerCrownVolume,
    DisplayableTreeProperty.OutlierHeightPerLeafArea,
    DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume,
    DisplayableTreeProperty.OutlierTrunkDiameterPerHeight,
    DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea,
    DisplayableTreeProperty.OverallOutlierIndex
  ];

  static getUnit(property: string, organization: Organization): string {
    if ([
      'externalId', 'species', 'managedAreaId',
      DisplayableTreeProperty.SafetyFactors,
      DisplayableTreeProperty.Slenderness
    ].includes(property)) {
      return '';
    }

    const unitMap = organization.isMetric ? Tree.UNIT_MAP : Tree.IMPERIAL_UNIT_MAP;
    if (this.ECONOMICAL_PROPERTY_VALUE.includes(property as DisplayableTreeProperty)) return organization.currency;
    return unitMap.get(property as DisplayableTreeProperty) ?? '';
  }

  static getWindSpeedUnit(organization: Organization): string {
    return organization.getIsMetrical() ? 'km/h' : 'mph';
  }

  static getTKeyForProperty(property: string, value?: string): string {
    if (Tree.isBooleanProperty(property) || (Tree.isViBooleanProperty(property) && ((value === 'true' || value === 'false') || typeof value === 'boolean'))) {
      return `details.properties.booleanLabels.${value}`;
    }
    if (!this.isTranslatableProperty(property) && value) return value;
    if (value) {
      if (property === DisplayableTreeProperty.ViStatus) {
        return `virtualInspection.status.${value}`;
      }
      if (property === DisplayableTreeProperty.Status) {
        return value ? `tree.statusTypes.${value}` : '-';
      }
      if (property === DisplayableTreeProperty.VitalityVigor) {
        return value ? `tree.vitalityVigorTypes.${value}` : '-';
      }
    }
    return `tree.${property}`;
  }

  static getDefaultSafetyFactorProperty(account?: Account): string {
    if (!account) return DisplayableTreeProperty.SafetyFactorAt80Kmh;
    return DisplayableTreeProperty.SafetyFactorAtDefaultWindSpeed;
  }

  static empty(): Tree {
    return Tree.fromPartialDto({});
  }

  static fromPartialDto(dto: Partial<TreeDto>): Tree {
    return new Tree(
      dto.id ?? '',
      dto.managedAreaId ?? '',
      dto.height ?? NaN,
      dto.trunkHeight ?? NaN,
      dto.trunkWidth ?? NaN,
      dto.trunkEllipseRadiusA ?? NaN,
      dto.trunkEllipseRadiusB ?? NaN,
      dto.trunkCircumference ?? NaN,
      dto.canopyHeight ?? NaN,
      dto.canopyWidth ?? NaN,
      dto.canopyEllipseRadiusA ?? NaN,
      dto.canopyEllipseRadiusB ?? NaN,
      dto.canopyCircumference ?? NaN,
      dto.canopyDirection ?? NaN,
      dto.canopyOffset?.coordinates ?? [NaN, NaN],

      dto.leafArea ?? NaN,
      dto.leafBiomass ?? NaN,
      dto.leafAreaIndex ?? NaN,
      dto.carbonStorage ?? NaN,
      dto.grossCarbonSequestration ?? NaN,
      dto.no2 ?? NaN,
      dto.so2 ?? NaN,
      dto.pm25 ?? NaN,
      dto.co ?? NaN,
      dto.o3 ?? NaN,
      dto.ndvi ?? NaN,
      dto.treeHealth ?? NaN,
      dto.carbonStorageEcoValue ?? NaN,
      dto.grossCarbonSequestrationEcoValue ?? NaN,
      dto.no2EcoValue ?? NaN,
      dto.so2EcoValue ?? NaN,
      dto.pm25EcoValue ?? NaN,
      dto.coEcoValue ?? NaN,
      dto.o3EcoValue ?? NaN,
      dto.avoidedRunoffEcoValue ?? NaN,
      dto.potentialEvapotranspiration ?? NaN,
      dto.transpiration ?? NaN,
      dto.oxygenProduction ?? NaN,

      dto.safetyFactorAt80Kmh ?? NaN,
      dto.safetyFactorAtDefaultWindSpeed ?? NaN,
      dto.location?.coordinates ?? [NaN, NaN, NaN],
      dto.safetyFactors ?? [],
      dto.externalId ?? '',
      dto.customerTreeId ?? '',
      dto.customerTagId ?? '',
      dto.customerSiteId ?? '',

      dto.localizedLocation?.coordinates ?? [NaN, NaN, NaN],
      dto.images ?? [],
      dto.managedArea ? ManagedArea.fromDto(dto.managedArea) : ManagedArea.empty(),
      dto.trunkDiameter ?? NaN,
      dto.genus ?? '',
      dto.species ?? '',
      dto.scientificName ?? '',
      dto.pointCloudPath ?? '',
      dto.environmentPointCloudPath ?? '',

      dto.evaporation ?? NaN,
      dto.waterIntercepted ?? NaN,
      dto.avoidedRunoff ?? NaN,
      dto.leaningVector?.coordinates ?? [NaN, NaN, NaN],
      dto.leaningAngle ?? NaN,

      dto.treeValueCavat ?? NaN,
      dto.treeValueKoch ?? NaN,
      dto.treeValueRado ?? NaN,
      dto.thermalComfort ?? NaN,
      dto.capturePointId ?? '',

      dto.dieback ?? NaN,
      dto.cohort ?? undefined,

      dto.status ?? '',
      dto.vitalityVigor ?? '',
      dto.crownLightExposure ?? NaN,
      dto.leafAreaPerCrownVolume ?? NaN,
      dto.liveCrownRatio ?? NaN,
      dto.slenderness ?? NaN,
      dto.viStatus ?? undefined,
      dto.mitigations ?? [],
      dto.cultivarOrVariety ?? '',
      dto.tmsCategory ?? '',
      dto.ageClass ?? '',
      dto.ageAtPlanting ?? NaN,
      dto.includedBark ?? null,
      dto.vat19 ?? null,
      dto.alnarpModel ?? null,
      dto.normaGranada ?? null,
      dto.limbs ?? [],
      dto.coDominantLimbs ?? null,
      dto.fork ?? null,
      dto.crossSectionalShape ?? null,
      dto.foliageNoneSeasonal ?? null,
      dto.foliageNoneDead ?? null,
      dto.normalFoliage ?? null,
      dto.chloroticFoliage ?? null,
      dto.necroticFoliage ?? null,
      dto.crownVolume ?? null,
      dto.criticalRootZone ?? null,
      dto.structuralCriticalRootZone ?? null,
      dto.userUpdatedProperties,
      dto.outlierHeightPerCrownVolume ?? null,
      dto.outlierHeightPerLeafArea ?? null,
      dto.outlierLeafAreaPerCrownVolume ?? null,
      dto.outlierTrunkDiameterPerCrownVolume ?? null,
      dto.outlierTrunkDiameterPerHeight ?? null,
      dto.outlierTrunkDiameterPerLeafArea ?? null,
      dto.overallOutlierIndex ?? null
    );
  }

  static fromDto(dto: TreeDto) {
    return new Tree(
      dto.id,
      dto.managedAreaId,
      dto.height,
      dto.trunkHeight,
      dto.trunkWidth,
      dto.trunkEllipseRadiusA,
      dto.trunkEllipseRadiusB,
      dto.trunkCircumference,
      dto.canopyHeight,
      dto.canopyWidth,
      dto.canopyEllipseRadiusA,
      dto.canopyEllipseRadiusB,
      dto.canopyCircumference,
      dto.canopyDirection,
      dto.canopyOffset?.coordinates,

      dto.leafArea,
      dto.leafBiomass,
      dto.leafAreaIndex,
      dto.carbonStorage,
      dto.grossCarbonSequestration,
      dto.no2,
      dto.so2,
      dto.pm25,
      dto.co,
      dto.o3,
      dto.ndvi,
      dto.treeHealth,
      dto.carbonStorageEcoValue,
      dto.grossCarbonSequestrationEcoValue,
      dto.no2EcoValue,
      dto.so2EcoValue,
      dto.pm25EcoValue,
      dto.coEcoValue,
      dto.o3EcoValue,
      dto.avoidedRunoffEcoValue,
      dto.potentialEvapotranspiration,
      dto.transpiration,
      dto.oxygenProduction,

      dto.safetyFactorAt80Kmh,
      dto.safetyFactorAtDefaultWindSpeed,
      dto.location.coordinates,
      dto.safetyFactors,
      dto.externalId,
      dto.customerTreeId,
      dto.customerTagId,
      dto.customerSiteId,

      dto.localizedLocation.coordinates,
      dto.images,
      ManagedArea.fromDto(dto.managedArea),
      dto.trunkDiameter,
      !dto.genus && !dto.species ? GH_DEFAULT : dto.genus,
      !dto.genus && !dto.species ? GH_DEFAULT : dto.species,
      !dto.scientificName ? GH_DEFAULT : dto.scientificName,
      dto.pointCloudPath,
      dto.environmentPointCloudPath,

      dto.evaporation,
      dto.waterIntercepted,
      dto.avoidedRunoff,
      dto.leaningVector?.coordinates ?? [NaN, NaN, NaN],
      dto.leaningAngle,

      dto.treeValueCavat,
      dto.treeValueKoch,
      dto.treeValueRado,
      dto.thermalComfort,
      dto.capturePointId,
      dto.dieback,
      dto.cohort,

      dto.status,
      dto.vitalityVigor,
      dto.crownLightExposure,
      dto.leafAreaPerCrownVolume,
      dto.liveCrownRatio,
      dto.slenderness,
      dto.viStatus,
      dto.mitigations,
      dto.cultivarOrVariety,
      dto.tmsCategory,
      dto.ageClass,
      dto.ageAtPlanting,
      dto.includedBark,
      dto.vat19,
      dto.alnarpModel,
      dto.normaGranada,
      dto.limbs,
      dto.coDominantLimbs,
      dto.fork,
      dto.crossSectionalShape,
      dto.foliageNoneSeasonal,
      dto.foliageNoneDead,
      dto.normalFoliage,
      dto.chloroticFoliage,
      dto.necroticFoliage,
      dto.crownVolume,
      dto.criticalRootZone,
      dto.structuralCriticalRootZone,
      dto.userUpdatedProperties,
      dto.outlierHeightPerCrownVolume,
      dto.outlierHeightPerLeafArea,
      dto.outlierLeafAreaPerCrownVolume,
      dto.outlierTrunkDiameterPerCrownVolume,
      dto.outlierTrunkDiameterPerHeight,
      dto.outlierTrunkDiameterPerLeafArea,
      dto.overallOutlierIndex
    );
  }

  static isTranslatableProperty(property: string): boolean {
    return this.TRANSLATABLE_ENUM_PROPERTIES.includes(property as DisplayableTreeProperty);
  }

  static isEnumProperty(property: string): boolean {
    return this.ENUM_PROPERTIES.includes(property as DisplayableTreeProperty);
  }

  static isViBooleanProperty(property: string | DisplayableTreeProperty): boolean {
    return [
      DisplayableTreeProperty.HasViObservation,
      DisplayableTreeProperty.HasMitigation,
      DisplayableTreeProperty.HasAssessmentRequest
    ].includes(property as DisplayableTreeProperty);
  }

  static isBooleanProperty(property: string | DisplayableTreeProperty): boolean {
    return [
      DisplayableTreeProperty.OutlierHeightPerCrownVolume,
      DisplayableTreeProperty.OutlierHeightPerLeafArea,
      DisplayableTreeProperty.OutlierLeafAreaPerCrownVolume,
      DisplayableTreeProperty.OutlierTrunkDiameterPerCrownVolume,
      DisplayableTreeProperty.OutlierTrunkDiameterPerHeight,
      DisplayableTreeProperty.OutlierTrunkDiameterPerLeafArea
    ].includes(property as DisplayableTreeProperty);
  }

  constructor(
    readonly id: string,
    readonly managedAreaId: string,
    readonly height: number,
    readonly trunkHeight: number,
    readonly trunkWidth: number,
    readonly trunkEllipseRadiusA: number,
    readonly trunkEllipseRadiusB: number,
    readonly trunkCircumference: number,
    readonly canopyHeight: number,
    readonly canopyWidth: number,
    readonly canopyEllipseRadiusA: number,
    readonly canopyEllipseRadiusB: number,
    readonly canopyCircumference: number,
    readonly canopyDirection: number,
    readonly canopyOffset: [number, number],
    readonly leafArea: number,
    readonly leafBiomass: number,
    readonly leafAreaIndex: number,
    readonly carbonStorage: number,
    readonly grossCarbonSequestration: number,
    readonly no2: number,
    readonly so2: number,
    readonly pm25: number,
    readonly co: number,
    readonly o3: number,
    readonly ndvi: number,
    readonly treeHealth: number,
    readonly carbonStorageEcoValue: number | null,
    readonly grossCarbonSequestrationEcoValue: number | null,
    readonly no2EcoValue: number | null,
    readonly so2EcoValue: number | null,
    readonly pm25EcoValue: number | null,
    readonly coEcoValue: number | null,
    readonly o3EcoValue: number | null,
    readonly avoidedRunoffEcoValue: number | null,
    readonly potentialEvapotranspiration: number,
    readonly transpiration: number,
    readonly oxygenProduction: number,
    readonly safetyFactorAt80Kmh: number | undefined,
    readonly safetyFactorAtDefaultWindSpeed: number | undefined,
    readonly location: [Longitude, Latitude, number],
    readonly safetyFactors: SafetyFactor[],
    readonly externalId: string,
    readonly customerTreeId: string,
    readonly customerTagId: string,
    readonly customerSiteId: string,
    readonly localizedLocation: [number, number, number],
    readonly images: TreeImage[],
    public managedArea: ManagedArea,
    readonly trunkDiameter: number,
    readonly genus: string,
    readonly species: string,
    readonly scientificName: string,
    readonly pointCloudPath: string,
    readonly environmentPointCloudPath: string,
    readonly evaporation: number,
    readonly waterIntercepted: number,
    readonly avoidedRunoff: number,
    readonly leaningVector: [number, number, number],
    readonly leaningAngle: number,
    readonly treeValueCavat: number,
    readonly treeValueKoch: number,
    readonly treeValueRado: number,
    readonly thermalComfort: number,
    readonly capturePointId: string,
    readonly dieback: number | undefined,
    readonly cohort: Cohort | undefined,
    readonly status: string,
    readonly vitalityVigor: string,
    readonly crownLightExposure: number | null,
    readonly leafAreaPerCrownVolume: number | null,
    readonly liveCrownRatio: number | null,
    readonly slenderness: number | null,
    readonly viStatus: ViStatus | undefined,
    readonly mitigations: Mitigation[],
    readonly cultivarOrVariety: string,
    readonly tmsCategory: string,
    readonly ageClass: string,
    readonly ageAtPlanting: number,
    readonly includedBark: boolean | null,
    readonly vat19: number | null,
    readonly alnarpModel: number | null,
    readonly normaGranada: number | null,
    readonly limbs: { diameter: number }[],
    readonly coDominantLimbs: boolean | null,
    readonly fork: Fork | null,
    readonly crossSectionalShape: CrossSectionalShape | null,
    readonly foliageNoneSeasonal: boolean | null,
    readonly foliageNoneDead: boolean | null,
    readonly normalFoliage: number | null,
    readonly chloroticFoliage: number | null,
    readonly necroticFoliage: number | null,
    readonly crownVolume: number | null,
    readonly criticalRootZone: number | null,
    readonly structuralCriticalRootZone: number | null,
    readonly userUpdatedProperties: string[] = [],
    readonly outlierHeightPerCrownVolume: boolean | null,
    readonly outlierHeightPerLeafArea: boolean | null,
    readonly outlierLeafAreaPerCrownVolume: boolean | null,
    readonly outlierTrunkDiameterPerCrownVolume: boolean | null,
    readonly outlierTrunkDiameterPerHeight: boolean | null,
    readonly outlierTrunkDiameterPerLeafArea: boolean | null,
    readonly overallOutlierIndex: number | null
  ) {
  }

  get firstBifurcation() {
    return this.trunkHeight;
  }

  get outliers() {
    return Tree.OUTLIER_PROPERTIES.filter(it => this[it] !== null);
  }

  getPointCloudUrl(organization: Organization): string {
    return organization.getCDNUrlOfTreeDataFromRelativePath(this.pointCloudPath);
  }

  getEnvironmentPointCloudUrl(organization: Organization): string {
    return organization.getCDNUrlOfTreeDataFromRelativePath(this.environmentPointCloudPath);
  }

  getCorridorClearingPointCloudUrl(organization: Organization): string {
    return organization.getCDNUrlFromRelativePath('tasks/' + this.managedArea.code + '/tree_segmentation_clips/' + this.externalId + '_road_clearing.laz');
  }

  getWireClearanceFullWireUrl(): string {
    return `/demo/${this.externalId}/All_Cables.gltf`;
  }

  getWireClearanceCollidedWireUrl(): string {
    return `/demo/${this.externalId}/Collided_Cables.gltf`;
  }

  getWindDirectionAngle(windSpeed: number): number | null {
    const safetyFactor = this.safetyFactors.find(it => it.windSpeed === windSpeed);
    if (!safetyFactor) {
      return null;
    }
    return safetyFactor.windDirection;
  }

  getMercatorCoordinates() {
    return new MercatorCoordinate(this.location);
  }

  hasProperty(property: string | null | undefined) {
    if (!property) {
      return false;
    }

    return !!(this[property] || this[property] === 0);
  }

  setManagedArea(managedArea: ManagedArea) {
    this.managedArea = managedArea;
  }

  hasImages() {
    return this.images.length > 0;
  }

  getMainImageURL(): string {
    return this.images.at(0)?.getRotatedUrl() ?? '';
  }

  getMainImageThumbnailURL(): string {
    return this.images.at(0)?.getThumbnailUrl() ?? '';
  }

  async getMainImageThumbnailFromLayersURL(): Promise<string> {
    const isNewEnvironment = getRuntimeConfig().isNewInfrastructure;

    if (!isNewEnvironment) {
      return this.getMainImageThumbnailURL();
    }
    return await this.images.at(0)?.getThumbnailUrlFromLayers() ?? '';
  }

  getWorldCoordinates(): [number, number] {
    const mercatorCoordinateMin = 20037508.34;
    return [
      (Math.atan(Math.exp((this.location[1] * Math.PI) / mercatorCoordinateMin)) * 360) / Math.PI - 90,
      (this.location[0] * 180) / mercatorCoordinateMin
    ];
  }

  getCoordinates() {
    return this.location.slice(0, 2) as [number, number];
  }

  getSafetyFactor(windSpeed: number) {
    return this.safetyFactors.find(it => it.windSpeed === windSpeed)?.safetyFactor || null;
  }

  getPropertyValue(property: string, windSpeed?: number) {
    if (property === 'safetyFactors') return windSpeed ? this.getSafetyFactor(windSpeed) : null;
    return this[property];
  }

  isUnsafe() {
    return (this.safetyFactorAt80Kmh ?? 0) < Tree.SAFETY_FACTOR_THRESHOLD;
  }

  getMaxWindSpeed() {
    return Math.max(...this.safetyFactors.map(it => it.windSpeed));
  }

  getMinWindSpeed() {
    return Math.min(...this.safetyFactors.map(it => it.windSpeed));
  }

  hasSafetyFactorAt(windSpeed: number) {
    return this.safetyFactors.some(it => it.windSpeed === windSpeed);
  }

  isSafeAt(windSpeed: number) {
    const safetyFactor = this.getSafetyFactor(windSpeed);
    if (safetyFactor === null) return null;
    return safetyFactor >= Tree.SAFETY_FACTOR_THRESHOLD;
  }

  testAgainst(property: DisplayableTreeProperty | null, threshold: number | null): ThresholdMatchingState {
    if (threshold === null || !this.hasProperty(property)) return ThresholdMatchingState.default();

    if (property === DisplayableTreeProperty.SafetyFactors) {
      if (!this.hasSafetyFactorAt(threshold)) return ThresholdMatchingState.default();
      if (this.isSafeAt(threshold)) return ThresholdMatchingState.matching();
      return ThresholdMatchingState.different();
    }

    if (this[property!]! >= threshold) return ThresholdMatchingState.matching();

    return ThresholdMatchingState.different();
  }

  isOutsideRange(property: DisplayableTreeProperty, from: number, to: number, isFirstRange: boolean) {
    if (isFirstRange) {
      return from > this[property] || to < this[property];
    }
    return from >= this[property] || to < this[property];
  }

  getRenderValue(property: string, t: TFunction): string {
    const enumProperties = [DisplayableTreeProperty.Status, DisplayableTreeProperty.VitalityVigor];
    const integerProperties = [
      DisplayableTreeProperty.Dieback,
      DisplayableTreeProperty.PlantingYear,
      DisplayableTreeProperty.NumberOfStems,
      DisplayableTreeProperty.Age,
      DisplayableTreeProperty.CustomerTreeId,
      DisplayableTreeProperty.CrownLightExposure
    ];

    if (this[property] === null || this[property] === null || this[property] === '' || this[property].length === 0)
      return t('treeDetails.noData');

    if (enumProperties.includes(property as DisplayableTreeProperty)) return t(`tree.${property}Types.` + this[property]);
    if (integerProperties.includes(property as DisplayableTreeProperty)) return this[property]!.toString();
    if (property === DisplayableTreeProperty.LocalizedLocation) return this.getWorldCoordinates().map(it => it.toFixed(5)).join(', ');
    if (typeof this[property] === 'number') return this[property].toFixed(2);

    return this[property];
  }
}

export interface TreeDisplayConfiguration {
  property: DisplayableTreeProperty | null,
  managedAreaIds: string[],
  isManagedAreaSelectionReversed: boolean,
  filters: TreeFilter[],
  windSpeed: number
}

export interface TreeDto {
  id: string,
  managedAreaId: string,
  height: number,
  trunkHeight: number,
  trunkWidth: number,
  trunkEllipseRadiusA: number,
  trunkEllipseRadiusB: number,
  trunkCircumference: number,
  canopyHeight: number,
  canopyWidth: number,
  canopyEllipseRadiusA: number,
  canopyEllipseRadiusB: number,
  canopyCircumference: number,
  canopyDirection: number,
  canopyOffset: { coordinates: [number, number] },

  leafArea: number,
  leafBiomass: number,
  leafAreaIndex: number,
  carbonStorage: number,
  grossCarbonSequestration: number,
  no2: number,
  so2: number,
  pm25: number,
  co: number,
  o3: number,
  ndvi: number,
  treeHealth: number,
  carbonStorageEcoValue: number,
  grossCarbonSequestrationEcoValue: number,
  no2EcoValue: number,
  so2EcoValue: number,
  pm25EcoValue: number,
  coEcoValue: number,
  o3EcoValue: number,
  avoidedRunoffEcoValue: number,
  potentialEvapotranspiration: number,
  transpiration: number,
  oxygenProduction: number,

  safetyFactorAt80Kmh: number,
  safetyFactorAtDefaultWindSpeed: number,
  safetyFactors: SafetyFactor[],
  location: {
    coordinates: [Longitude, Latitude, number]
  },
  localizedLocation: {
    coordinates: [Longitude, Latitude, number]
  },
  externalId: string,
  customerTreeId: string,
  customerTagId: string,
  customerSiteId: string,
  images: TreeImage[],
  managedArea: ManagedAreaDto,
  trunkDiameter: number,
  genus: string,
  species: string,
  scientificName: string,
  pointCloudPath: string,
  environmentPointCloudPath: string,
  evaporation: number,
  waterIntercepted: number,
  avoidedRunoff: number,
  leaningVector: {
    coordinates: [number, number, number]
  } | null,
  leaningAngle: number,
  treeValueCavat: number,
  treeValueKoch: number,
  treeValueRado: number,
  thermalComfort: number,
  capturePointId: string,
  cohort: Cohort,
  dieback: number,
  viStatus: ViStatus,
  status: string,
  vitalityVigor: string,
  crownLightExposure: number,
  leafAreaPerCrownVolume: number,
  liveCrownRatio: number,
  slenderness: number,
  hasViObservation: boolean,
  hasMitigation: boolean,
  hasAssessmentRequest: boolean,
  mitigations: Mitigation[],
  cultivarOrVariety: string,
  tmsCategory: string,
  ageClass: string,
  ageAtPlanting: number,
  includedBark: boolean | null,
  vat19: number,
  alnarpModel: number,
  normaGranada: number,
  limbs: { diameter: number }[],
  coDominantLimbs: boolean,
  fork: Fork,
  crossSectionalShape: CrossSectionalShape,
  foliageNoneSeasonal: boolean | null,
  foliageNoneDead: boolean | null,
  normalFoliage: number | null,
  chloroticFoliage: number | null,
  necroticFoliage: number | null,
  crownVolume: number | null,
  environment?: Partial<TreeEnvironment>,
  criticalRootZone: number | null,
  structuralCriticalRootZone: number | null,
  userUpdatedProperties: string[],
  outlierHeightPerCrownVolume: boolean | null,
  outlierHeightPerLeafArea: boolean | null,
  outlierLeafAreaPerCrownVolume: boolean | null,
  outlierTrunkDiameterPerCrownVolume: boolean | null,
  outlierTrunkDiameterPerHeight: boolean | null,
  outlierTrunkDiameterPerLeafArea: boolean | null,
  overallOutlierIndex: number | null
}

export interface DisplayableViItem {
  name: string,
  residualRisk?: string
}

export interface Mitigation extends DisplayableViItem{
  taskTemplateId: string,
  taskTemplate: TaskTemplate,
  taskId: string
}

export interface SafetyFactor {
  safetyFactor: number,
  windDirection: number,
  windSpeed: number,
  weakestPoint: number
}
