import React, { Children, ReactElement, useContext } from 'react';

import Sidebar from './Sidebar';
import { useCurrentAccount } from '../../../../account/useAccounts';
import {
  Header,
  HeaderName, HeaderPanel,
  Theme
} from '@carbon/react';
import greeHillLogoDark from '../../../UI/Icon/svg/greehill-logo.svg';
import greeHillLogoLight from '../../../UI/Icon/svg/greehill-logo-light.svg';
import useCarbonThemes, { CarbonTheme } from '../../Carbon/useCarbonThemes';
import ProfileMenu from './ProfileMenu';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';

export default function PageLayout(props: PageLayoutProps) {
  const { theme } = useCarbonThemes();
  const account = useCurrentAccount();
  const { urlContext } = useContext(DependencyInjectionContext);
  const isSideBarOpen = urlContext.getSidebarOpen();

  const content = Children.map(props.children, child => (child.type === Content ? child : null));
  const centerNavExtensions = Children.map(props.children, child => (child.type === CenterNavExtensions ? child : null));
  const rightPanel = Children.map(props.children, child => (child.type === RightPanel && child.props.isExpanded ? child : null));

  return (
    <Theme theme={theme} className="normalize-scrollbar">
      <div
        style={{ backgroundColor: 'var(--cds-background)' }}
        className="w-[100vw] h-[100vh] fixed left-0 top-0 -z-10"/>
      <Header className="flex justify-between items-center" aria-label="header">
        <HeaderName prefix="">
          <img src={getLogoUrl(theme)} alt="greeHill"/>
        </HeaderName>

        {centerNavExtensions}

        <ProfileMenu currentAccount={account}/>
        <HeaderPanel
          className={(rightPanel.length > 0 ? 'w-[374px]' : '') + ' z-10'}
          expanded={rightPanel.length > 0}>{rightPanel}</HeaderPanel>
      </Header>
      {account.isEmployeeOrAbove() && <Sidebar/>}
      <div className={(isSideBarOpen ? 'pl-[255px]' : 'pl-12') + ' pt-12 h-[100vh] w-[100vw]'}>
        {content}
      </div>
    </Theme>
  );
}

function CenterNavExtensions({ children }) {
  return children;
}

function RightPanel({ children, isExpanded }) {
  return <div className="relative pb-16 h-full">
    {children}
  </div>;
}

function RightPanelContent({ children }) {
  return <div className="py-2 h-full overflow-y-auto">{children}</div>;
}

function RightPanelActions({ children }) {
  return <div className="w-full absolute bottom-0 left-0">{children}</div>;
}

PageLayout.CenterNavExtensions = CenterNavExtensions;
PageLayout.RightPanel = RightPanel;
PageLayout.RightPanelContent = RightPanelContent;
PageLayout.RightPanelActions = RightPanelActions;

function Content({ children }) {
  return children;
}

PageLayout.Content = Content;

interface PageLayoutProps {
  children: ReactElement | ReactElement[]
}

function getLogoUrl(theme: CarbonTheme) {
  return [CarbonTheme.g90, CarbonTheme.g100].includes(theme) ? greeHillLogoDark : greeHillLogoLight;
}
