import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentAccount } from '../../../account/useAccounts';
import { FilterableMultiSelect } from '@carbon/react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useAvailableColumnSelectorProperties } from '../../../properties/usePropertyConfigurations';
import { Flippers } from '../../../switches/Flippers';
import { DisplayableTreeProperty } from '../../../tree/Tree';

export default function ColumnSelector(props: { disabled: boolean }) {
  const { urlContext } = useContext(DependencyInjectionContext);
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const properties = useAvailableColumnSelectorProperties(organization.isEnabled(Flippers.release24q3));
  const items = Object.values(properties).flat().filter(it => it !== DisplayableTreeProperty.SafetyFactors);
  const selectedItems = items.filter(it => urlContext.getVisibleTableProperties()?.includes(it));

  const filterItems = (x, extra) => {
    return items.filter(it => t(`tree.${it}`).toLowerCase().includes(extra.inputValue.toLowerCase()));
  };
  const placeholder = () => {
    if (selectedItems.length > 1) return t('columnSelector.columnsSelected');
    return selectedItems.length ? t('columnSelector.columnSelected') : t('columnSelector.selectColumns');
  };
  const handleChange = (data: { selectedItems }) => {
    urlContext.setVisibleTableProperties(data.selectedItems);
  };
  return (
    <FilterableMultiSelect
      id="column-selector"
      placeholder={placeholder()}
      items={items}
      selectedItems={selectedItems}
      filterItems={filterItems}
      onChange={handleChange}
      itemToString={it => it ? t(`tree.${it}`) : ''}
      disabled={props.disabled}
      size="lg"
    />
  );
}
