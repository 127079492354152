import { LoadableLegacyPointCloud } from './LoadableLegacyPointCloud';
import { LoadableShaderPointCloud } from './LoadableShaderPointCloud';
import * as THREE from 'three';
import { Tree } from '../tree/Tree';
import getRuntimeConfig from '../RuntimeConfig';

const USE_SHADER_POINTCLOUD = getRuntimeConfig().enhancedPointCloud;

export interface LoadablePointCloudInterface {
  loadInto(position: number[], isEnvironment: boolean, pointsMaterial?: THREE.PointsMaterial): Promise<LoadableLegacyPointCloud | LoadableShaderPointCloud>,

  loadIntoWithTreeId(treeId: string, position: number[], pointsMaterial?: THREE.PointsMaterial): Promise<LoadableLegacyPointCloud | LoadableShaderPointCloud>,

  belongsTo(tree: Tree): boolean

}

export class LoadablePointCloud extends (USE_SHADER_POINTCLOUD ? LoadableShaderPointCloud : LoadableLegacyPointCloud) implements LoadablePointCloudInterface {
  loadIntoWithTreeId(treeId: string, position: number[], pointsMaterial?: THREE.PointsMaterial | undefined, hideCanopy?: boolean): Promise<LoadableLegacyPointCloud | LoadableShaderPointCloud> {
    return super.loadIntoWithTreeId(treeId, position, pointsMaterial, hideCanopy);
  }

  belongsTo(tree: Tree): boolean {
    return super.belongsTo(tree);
  }

  loadInto(position: number[], isEnvironment: boolean, pointsMaterial?: THREE.PointsMaterial | undefined, hideCanopy?: boolean): Promise<LoadableLegacyPointCloud | LoadableShaderPointCloud> {
    return super.loadInto(position, isEnvironment, pointsMaterial, hideCanopy);
  }
}
