import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import { useCurrentAccount } from '../../../account/useAccounts';
import { FilterableMultiSelect } from '@carbon/react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { ManagedArea } from '../../../managed-area/ManagedArea';

export default function ManagedAreaSelector() {
  const { urlContext } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const { t } = useTranslation();
  const { managedAreaList } = useManagedAreas(organization.id);
  const items = managedAreaList;
  const selectedItems = items.filter(it => urlContext.getManagedAreaIds().includes(it.id));

  const filterItems = (x, extra) => {
    return items.filter(it => it.getName()?.toLowerCase().includes(extra.inputValue.toLowerCase()));
  };
  const placeholder = () => {
    if (selectedItems.length > 1) return t('areaSelector.areasSelected');
    return selectedItems.length ? t('areaSelector.areaSelected') : t('areaSelector.selectArea');
  };
  const handleChange = (data: { selectedItems: ManagedArea[] }) => {
    urlContext.setManagedAreaIds(data.selectedItems.map(it => it.id));
  };
  return (
    <FilterableMultiSelect
      id="managed-area-selector"
      placeholder={placeholder()}
      items={items}
      selectedItems={selectedItems}
      itemToString={item => item ? item.getName() : ''}
      filterItems={filterItems}
      onChange={handleChange}
      size="lg"
    />
  );
}
