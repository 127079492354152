import { Button, SideNav, SideNavItems, SideNavLink } from '@carbon/react';
import { ChevronLeft, ChevronRight, Plan, Task, TaskAdd } from '@carbon/icons-react';
import React, { useContext } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { useMatch, useNavigate } from 'react-router-dom';
import { useCurrentAccount } from '../../../../account/useAccounts';
import { useTranslation } from 'react-i18next';
import { TaskManagerView } from '../../../../routes/TaskManager/TaskManagerView';
import { useAreaFilteringAsSearchParam } from '../../../../routes/TaskManager/create/useAreaFilteringAsSearchParam';

export default function Sidebar() {
  const { urlContext } = useContext(DependencyInjectionContext);
  const match = useMatch('/organizations/:organizationId/:currentItem/:currentSubItem/*');
  const navigate = useNavigate();
  const account = useCurrentAccount();
  const { t } = useTranslation();

  const isSideBarOpen = urlContext.getSidebarOpen();

  const currentSubItem = match?.params.currentSubItem ?? '';

  const inventorySearchParams = new URLSearchParams(localStorage.getItem('inventorySearchParams') ?? '');
  inventorySearchParams.set('s', isSideBarOpen ? 'true' : 'false');
  const sidebarParam = isSideBarOpen ? 's=true' : '';
  const areaFilteringAsSearchParam = [useAreaFilteringAsSearchParam(), sidebarParam].join('&');

  const toggleSidebar = () => urlContext.setSidebarOpen(!isSideBarOpen);

  return (
    <SideNav
      style={{ borderRight: '1px solid var(--cds-border-subtle)' }}
      expanded={isSideBarOpen}
      isRail
      isChildOfHeader={false}
      aria-label="Side navigation"
      addMouseListeners={false}
      addFocusListeners={false}
    >
      <SideNavItems className="pt-0">
        <div className="flex flex-row-reverse">
          <Button kind="ghost" large onClick={toggleSidebar}>{isSideBarOpen ? <ChevronLeft /> : <ChevronRight />}</Button>
        </div>
        <SideNavLink
          isActive={true}
          large
          renderIcon={Plan}
          onClick={() => navigate({
            pathname: `/organizations/${account.organization.id}/inventory`,
            search: inventorySearchParams.toString()
          })}
        >
          <div className="font-normal">{t('mainMenu.insights')}</div>
        </SideNavLink>
        <SideNavLink
          large
          renderIcon={Task}
          onClick={() => navigate({
            pathname: `/organizations/${account.organization.id}/task-manager/${TaskManagerView.MANAGE}`,
            search: sidebarParam
          })}
        >
          <div className="font-normal">{t('mainMenu.taskManagerManage')}</div>
        </SideNavLink>
        <SideNavLink
          large
          renderIcon={TaskAdd}
          onClick={() => {
            if (currentSubItem === TaskManagerView.MANAGE) return;
            navigate({
              pathname: `/organizations/${account.organization.id}/task-manager/${TaskManagerView.CREATE}`,
              search: areaFilteringAsSearchParam
            });
          }}
        >
          <div className="font-normal">{t('mainMenu.taskManagerCreate')}</div>
        </SideNavLink>
      </SideNavItems>
    </SideNav>
  );
}