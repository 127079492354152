import * as THREE from 'three';
import LASLoader, { LASLoaderResult } from '../components/_QUARANTINE/pointclouds/LASLoader';
import OptimizedPointcloud from './OptimizedPointcloud';
import { Tree } from '../tree/Tree';
import { LoadablePointCloudInterface } from './LoadablePointCloud';

let cache: Record<string, { pointCloudCenter: number[], optimizedPointCloud: OptimizedPointcloud }> = {};

export class LoadableLegacyPointCloud extends THREE.Group implements LoadablePointCloudInterface{
  private treeId: string | null = null;

  constructor(
    private readonly url: string,
    private readonly pointSize: number = 0.2
  ) {
    super();
  }

  loadIntoWithTreeId(treeId: string, position: number[], pointsMaterial?: THREE.PointsMaterial): Promise<LoadableLegacyPointCloud> {
    this.treeId = treeId;
    return this.loadInto(position, false, pointsMaterial);
  }

  belongsTo(tree: Tree) {
    return this.treeId === tree.id;
  }

  loadInto(position: number[], isEnvironment: boolean, pointsMaterial?: THREE.PointsMaterial, hideCanopy?:boolean): Promise<LoadableLegacyPointCloud> {
    return new Promise<LoadableLegacyPointCloud>(resolve => {
      const cacheKey = this.getCacheKey(!!pointsMaterial);
      const cached = cache[cacheKey];
      if (cached) {
        this.setupFromData(position, cached.pointCloudCenter, cached.optimizedPointCloud);
        return resolve(this);
      }

      new LASLoader(new THREE.LoadingManager()).load(this.url, isEnvironment, (pointCloud: LASLoaderResult) => {
        const optimizedPointCloud = this.optimizePointCloud(pointCloud, pointsMaterial);
        const pointCloudCenter = pointCloud.pc.mins;
        this.setupFromData(position, pointCloudCenter, optimizedPointCloud);

        const size = Object.values(cache).length;
        if (size > 3) cache = {};
        cache[cacheKey] = { pointCloudCenter, optimizedPointCloud };
        resolve(this);
      });
    });
  }

  private getCacheKey(useHistory: boolean) {
    return `${this.url}-${useHistory ? 'history' : 'original'}`;
  }

  private optimizePointCloud(pc: LASLoaderResult, pointsMaterial?: THREE.PointsMaterial) {
    const material = pointsMaterial ?? pc.createMaterial(THREE, this.pointSize);
    const op = new OptimizedPointcloud(pc.geometry, material);
    material.dispose();
    pc.material.dispose();
    pc.geometry.dispose();
    return op;
  }

  private setupFromData(into: number[], center: number[], optimizedPointCloud: OptimizedPointcloud) {
    this.position.subVectors(new THREE.Vector3().fromArray(center), new THREE.Vector3().fromArray(into));
    this.add(optimizedPointCloud.clone());
  }

  dispose() {
    this.traverse(it => {
      if (it instanceof THREE.Points) {
        it.geometry.dispose();
        it.material.dispose();
      }
    });
  }
}
