import { Button, FluidForm, TextInput } from '@carbon/react';
import FluidDropdown from '@carbon/react/es/components/FluidDropdown/FluidDropdown';
import FluidTextInput from '@carbon/react/es/components/FluidTextInput/FluidTextInput';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';
import { AuthContext } from '../../auth/AuthContext';
import { Checkmark, Close } from '@carbon/icons-react';
import { languages } from '../../account/LanguageSelector';
import { UserService } from '../../users/UserService';
import { Password, WeakPasswordError } from '../../password/Password';
import { PasswordService, PasswordUpdateError } from '../../password/PasswordService';
import { toast } from 'react-hot-toast';
import useCarbonThemes, { CarbonTheme } from '../../components/UI/Carbon/useCarbonThemes';

export default function UserPreferences() {
  const { t } = useTranslation();
  const { theme, setTheme } = useCarbonThemes();
  const authContext = useContext(AuthContext);
  const user = authContext.user;
  const translation = useTranslation();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVerification, setNewPasswordVerification] = useState('');

  const passwordErrors = useMemo(() => {
    if (!newPassword) {
      return [];
    }
    return new Password(newPassword).getErrors();
  }, [newPassword]);

  const updateLanguage = async language => {
    const updated = await UserService.make().updateSelf({ language });
    authContext.update(updated);
    await translation.i18n.changeLanguage(language);
    localStorage.setItem('languageSetBeforeLogin', language || '');
  };

  const handleFormSubmit = async event => {
    event.preventDefault();

    const firstName = event.target['first-name'].value;
    const lastName = event.target['last-name'].value;

    if (lastName !== user.lastName || firstName !== user.firstName) {
      const updatedUser = {};
      if (firstName !== user.firstName) updatedUser['firstName'] = firstName;
      if (lastName !== user.lastName) updatedUser['lastName'] = lastName;

      const response = await UserService.make().updateSelf(updatedUser);
      authContext.update(response);
    }

    if (newPassword.length && newPassword === newPasswordVerification && passwordErrors.length === 0) {
      try {
        await PasswordService.make().update(currentPassword, newPassword, translation.i18n.language);
      } catch (error: any) {
        if (error.message === PasswordUpdateError.NotMatching) {
          toast.error(error.message);
        }
      }
    }
    event.target.reset();
  };

  return (
    <> {/* TODO: add divider  */}
      <div className="max-w-[545px] mx-auto flex flex-col gap-16">
        <div>
          <div className="mb-4 font-sm font-semibold">{t('settings.general')}</div>
          <div className="flex gap-0.5">
            <FluidDropdown
              id="language"
              initialSelectedItem={languages.find(it => it.id === user.language) || languages[0]}
              titleText={t('settings.language')}
              label={t('settings.chooseAnOption')}
              onChange={it => updateLanguage(it.selectedItem.id)}
              items={languages}
              itemToString={item => t(item.translationKey)}
              className="w-full"
            />
            <FluidDropdown
              id="theme"
              titleText={t('settings.theme')}
              label={t('settings.chooseAnOption')}
              selectedItem={theme}
              items={Object.values(CarbonTheme)}
              onChange={e => setTheme(e.selectedItem)}
              itemToString={item => t(`settings.themes.${item}`)}
              className="w-full"
            />
          </div>
        </div>
        <FluidForm onSubmit={handleFormSubmit}>
          <div>
            <div className="mb-4 font-sm font-semibold">Name</div>
            <div className="flex gap-0.5">
              <TextInput
                type="text"
                labelText="First name"
                id="first-name"
                autoComplete="given-name"
                defaultValue={user.firstName} />
              <TextInput
                type="text"
                labelText="Last name"
                id="last-name"
                autoComplete="family-name"
                defaultValue={user.lastName} />
              {/*TODO: figure out why the last name autocomplete suggest emails*/}
            </div>
          </div>
          <div className="mt-12">
            <div className="mb-6 font-sm font-semibold">Change password</div>
            <div className="flex flex-col gap-6">
              <FluidTextInput
                type="password"
                labelText={t('settings.currentPassword')}
                id="current-password"
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
              />
              <FluidTextInput
                type="password"
                labelText={t('userPreferences.newPasswordLabel')}
                id="new-password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
              <FluidTextInput
                type="password"
                labelText={t('userPreferences.newPasswordVerifyLabel')}
                id="verify-new-password"
                value={newPasswordVerification}
                onChange={e => setNewPasswordVerification(e.target.value)}
              />
              <div>
                <div className="mb-2 font-sm font-semibold">Your password should</div>
                <ul className="flex flex-col gap-2">
                  <PasswordRequirementsListItem
                    isAccepted={newPassword.length > 0 && !passwordErrors.includes(WeakPasswordError.TooShort)}
                    label="be least 10 characters in length" />
                  <PasswordRequirementsListItem
                    isAccepted={newPassword.length > 0 && !passwordErrors.includes(WeakPasswordError.NoSmallCaps)}
                    label="contain lower case letters (a-z)" />
                  <PasswordRequirementsListItem
                    isAccepted={newPassword.length > 0 && !passwordErrors.includes(WeakPasswordError.NoUpperCase)}
                    label="contain upper case letters (A-Z)" />
                  <PasswordRequirementsListItem
                    isAccepted={newPassword.length > 0 && !passwordErrors.includes(WeakPasswordError.NoSpecialCharacters)}
                    label="contain a special character ($€+!?;., etc...)" />
                </ul>
              </div>
            </div>
          </div>
          <Button className="float-right mt-8" type="submit">Save changes</Button>
        </FluidForm>
      </div>
    </>
  );
}

const PasswordRequirementsListItem = (props: {isAccepted: boolean, label: string}) => (
  <li className="flex items-center">
    {props.isAccepted ? <Checkmark className='text-success-500' /> : <Close className="text-danger-500" />}
    <span className="ml-1">{props.label}</span>
  </li>
);
